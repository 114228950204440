.content {
  border: 8px solid rgba($c-white, .5);
  position: relative;
  z-index: 5;
  opacity: 0;

  &.active {
    opacity: 1;
  }

  @include mq(xxs, sm) {
    margin-top: -50px;
    margin-bottom: 50px;
  }

  @include mq(sm, md) {
    margin-top: -80px;
    margin-bottom: 60px;
  }

  @include mq(md) {
    margin-top: -110px;
    margin-bottom: 80px;
  }

  &__item {
    background-color: $c-white;

    @include mq(xxs, sm) {
      padding: 0 15px;
    }

    @include mq(sm, md) {
      padding: 0 20px;
    }

    @include mq(md, lg) {
      padding: 0 40px;
    }

    @include mq(lg) {
      padding: 0 74px;
    }
  }

  &__widgets {
    max-width: 670px;
    margin: 0 auto;
  }

  &__widget-cats {
    ul {
      list-style: none;
      margin: 0;
      padding: 0 0 35px;
      display: flex;
      flex-wrap: wrap;
    }

    li {
      margin: 0 10px 10px 0;
    }
  }

  &__widget-title {
    font-size: 21px;
    line-height: 1.2;
    font-weight: 400;
    margin: 0 0 20px;
    padding: 0 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $c-gray;

    a {
      font-size: 16px;
      color: $c-blue;
    }
  }
}

.text {
  &__item {
    max-width: 670px;
    margin: 0 auto;
    
    h2 {
      font-weight: 500;
      line-height: 1.5;
      color: $s;
      margin: 25px 0 10px;

      @include mq(xxs, sm) {
        font-size: 24px;
      }

      @include mq(sm, md) {
        font-size: 26px;
      }

      @include mq(md) {
        font-size: 28px;
      }
    }
    
    h3 {
      font-weight: 500;
      line-height: 1.5;
      color: $s;
      margin: 15px 0 5px;

      @include mq(xxs, sm) {
        font-size: 20px;
      }

      @include mq(sm, md) {
        font-size: 22px;
      }

      @include mq(md) {
        font-size: 24px;
      }
    }

    p {
      line-height: 1.6666666667;
      font-weight: 300;

      @include mq(xxs, sm) {
        font-size: 16px;
        margin: 0 0 20px;
      }

      @include mq(sm, md) {
        font-size: 17px;
        margin: 0 0 25px;
      }

      @include mq(md) {
        font-size: 18px;
        margin: 0 0 30px;
      }
    }

    ul {
      padding: 0 0 0 18px;

      @include mq(xxs, sm) {
        margin: 0 0 20px;
      }

      @include mq(sm, md) {
        margin: 0 0 25px;
      }

      @include mq(md) {
        margin: 0 0 30px;
      }
    }

    li {
      line-height: 1.6666666667;
      font-weight: 300;

      @include mq(xxs, sm) {
        font-size: 16px;
      }

      @include mq(sm, md) {
        font-size: 17px;
      }

      @include mq(md) {
        font-size: 18px;
      }
    }
  }

  blockquote {
    text-align: center;
    margin: 0 auto;
    padding: 30px 0 60px;
    max-width: 970px;

    h2 {
      font-weight: 400;
      line-height: 1.5;
      color: $s;
      margin: 0 0 20px;

      @include mq(xxs, sm) {
        font-size: 20px;
      }

      @include mq(sm, md) {
        font-size: 22px;
      }

      @include mq(md) {
        font-size: 24px;
      }
    }

    p {
      line-height: 1.2;
      font-weight: 300;
      margin: 0;

      @include mq(xxs, sm) {
        font-size: 19px;
      }

      @include mq(sm, md) {
        font-size: 20px;
      }

      @include mq(md) {
        font-size: 21px;
      }
    }
  }
}

.post-navi {
  max-width: 670px;
  margin: 0 auto;
  display: flex;

  @include mq(xxs, sm) {
    padding: 30px 0 35px;
    flex-direction: column;

    .btn {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include mq(sm, md) {
    padding: 35px 0 40px;
    justify-content: space-between;
  }

  @include mq(md) {
    padding: 40px 0 45px;
    justify-content: space-between;
  }
}

.comments {
  margin: 0 0 20px;
}

.comment {
  padding: 10px 0 25px;

  h3 {
    line-height: 1.2;
    font-weight: 400;
    margin: 0 0 5px;

    @include mq(xxs, sm) {
      font-size: 16px;
    }

    @include mq(sm, md) {
      font-size: 17px;
    }

    @include mq(md) {
      font-size: 18px;
    }

    span {
      font-weight: 300;
    }
  }

  p {
    font-weight: 300;
    margin: 0;

    @include mq(xxs, sm) {
      font-size: 14px;
    }

    @include mq(sm, md) {
      font-size: 15px;
    }

    @include mq(md) {
      font-size: 16px;
    }
  }
}

.add-comment {
  padding: 0 0 60px;
}