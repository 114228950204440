.search-results {
  padding-top: 50px;
  max-width: 1150px;
  margin: 0 auto;

  &__item {
    border-bottom: 1px solid $c-gray;
    opacity: 0;

    a {
      color: $c-blue;
      &:hover {
        color: $c-orange;
      }
    }

    .sr-city {
      color: $c-orange;
    }
    
    @include mq(xxs, sm) {
      padding: 20px 0;
    }

    @include mq(sm) {
      padding: 30px 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  h3 {    
    font-weight: 400;
    line-height: 1.7;
    margin: 0;

    @include mq(xxs, sm) {
      font-size: 17px;
    }

    @include mq(sm) {
      font-size: 18px;
    }
  }

  p {    
    font-weight: 300;
    line-height: 1.7;
    margin: 0;
    
    @include mq(xxs, sm) {
      font-size: 17px;
    }

    @include mq(sm) {
      font-size: 18px;
    }
  }

  &__hidden {
    display: none;
  }

  &__more,
  &__less {
    color: $c-orange;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__load-more {
    text-align: center;
    opacity: 0;

    @include mq(xxs, sm) {
      padding: 30px 0 30px;
    }

    @include mq(sm) {
      padding: 45px 0 40px;
    }

    span {
      display: block;
      width: 8px;
      height: 8px;
      border-top: 1px solid $c-white;
      border-right: 1px solid $c-white;
      transform: rotate(135deg);
      margin: 0 15px 2px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}