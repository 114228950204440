a {
  color: $c-link;
  text-decoration: none;
  cursor: pointer;
  background: transparent;
}

.color-orange {
  color: $c-orange;
}

.color-blue {
  color: $c-blue;
}

.icon {
  display: inline-block;
  vertical-align: middle;
}

.page-title {
  font-weight: 500;
  color: $p;
  margin: 0;

  @include mq(xxs, sm) {
    font-size: 24px;
    padding: 30px 0;
  }

  @include mq(sm, md) {
    font-size: 26px;
    padding: 50px 0 40px;
  }

  @include mq(md) {
    font-size: 28px;
    padding: 55px 0 60px;
  }

  &--centered {
    text-align: center;
  }
}

.page-item-title {
  max-width: 720px;
  margin: 0 auto;

  @include mq(xxs, sm) {
    padding: 35px 0;
  }

  @include mq(sm, md) {
    padding: 45px 0;
  }

  @include mq(md) {
    padding: 55px 0;
  }

  h1 {
    font-weight: 500;
    line-height: 1.12;
    color: $p;
    margin: 0 0 25px;

    @include mq(xxs, sm) {
      font-size: 34px;
    }

    @include mq(sm, md) {
      font-size: 38px;
    }

    @include mq(md) {
      font-size: 42px;
    }
  }

  span {
    background-color: $c-orange;
    width: 120px;
    height: 6px;
    display: block;
    margin: 0 auto;
  }

  &--centered {
    text-align: center;
  }
}