.blog {
  border-top: 1px solid $c-gray;
  padding: 45px 0;
  display: flex;
  flex-wrap: wrap;
  max-width: 1140px;
  margin: 0 auto;
  opacity: 0;

  &.active {
    opacity: 1;
  }
  
  &__posts {
    flex: 1;

    @include mq(xxs, sm) {
      width: 100%;
    }

    @include mq(sm, md) {
      margin-right: 40px;
    }

    @include mq(md) {
      margin-right: 80px;
    }
  }

  &__sidebar {
    @include mq(xxs, sm) {
      padding-top: 50px;
    }

    @include mq(sm, md) {
      flex: 0 0 240px;
    }

    @include mq(md) {
      flex: 0 0 340px;
    }
  }

  &__widget {
    margin: 0 0 40px;

    ul,
    ol {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    ul li {
      margin: 0 0 10px;

      a {
        font-size: 16px;
        line-height: 1.2;
        color: $c-blue;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    ol li {
      margin: 0 0 8px;
    }
  }

  &__sidebar-title {
    font-size: 21px;
    line-height: 1.2;
    font-weight: 400;
    margin: 0 0 20px;
    padding: 0 0 20px;
    border-bottom: 1px solid $c-gray;
  }

  &__post {
    margin: 0 0 25px;
    padding: 0 0 25px;
    border-bottom: 1px solid $c-gray;

    iframe {
      width: 100%;
    }

    h2 {
      font-weight: 500;
      line-height: 1.5;
      color: $p;
      margin: 0 0 25px;

      @include mq(sm, md) {
        font-size: 22px;
      }

      @include mq(md) {
        font-size: 24px;
      }

      a {
        transition: .2s ease-in-out;
        display: inline-block;

        &:hover {
          color: $c-blue;
        }
      }
    }

    p {
      font-weight: 300;
      line-height: 1.7;

      @include mq(sm,md) {
        font-size: 16px;
      }

      @include mq(md) {
        font-size: 18px;
      }
    }
  }

  &__image {
    display: block;
  }

  &__image--video {
    position: relative;

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__load-more {
    text-align: center;
  }

  &__post--video {
    border-bottom: 0;
    padding: 0;
  }
}