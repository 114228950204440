input,
textarea {
  -webkit-appearance: none;
  border-radius: 0;
  width: 100%;
  border: 0;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  line-height: 1.2;
  border: 0;
  padding: 8px 30px 9px;
  border-radius: 30px;
  transition: background .2s ease-out, color .2s ease-out;

  @include mq(xxs, md) {
    font-size: 16px;
  } 

  @include mq(sm) {
    min-width: 140px;
  } 

  @include mq(md) {
    font-size: 18px;
  } 

  &--small {
    padding: 5px 25px 6px;    
  }

  &--outline {
    border-width: 1px;
    border-style: solid;
  }

  &--blue {
    background-color: $c-white;
    border-color: $c-blue;
    color: $c-blue;

    svg {
      fill: $c-blue;
    }

    &:hover {
      background-color: $c-blue;
      color: $c-white;

      svg {
        fill: $c-white;
        transform: translateX(5px);
      }
    }
  }

  &:disabled {
    background-color: $tr;
    border-color: $c-gray;
    color: rgba($c-primary, .5);
    cursor: not-allowed;

    svg {
      fill: $c-gray;
    }

    &:hover {
      background-color: $tr;
      color: $c-gray;

      svg {
        fill: $c-gray;
      }
    }
  }

  &--blue-bg {
    background-color: $c-blue;
    color: $c-white;
    border: 1px solid $tr;

    svg {
      fill: $c-white;
    }

    &:hover {
      background-color: $c-orange;
      color: $c-white;

      svg {
        transform: translateX(5px);
      }
    }
  }

  &--white {
    border-color: $c-white;
    color: $c-white;

    svg {
      fill: $c-white;
    }

    &:hover {
      background-color: $c-white;
      color: $c-black;

      svg {
        fill: $c-black;
        transform: translateX(5px);
      }
    }
  }  

  svg {
    transition: fill .2s ease-out, transform .2s ease;
  }

  .icon-arrow {
    margin-left: 13px;
    width: 18px;
    height: 15px;
    position: relative;
    top: 1px;
  }

  &--flip {
    .icon-arrow {
      margin: 0 13px 0 0;
      transform: scaleX(-1);
      filter: FlipH;
    }

    &:hover {
      .icon-arrow {
        transform: scaleX(-1) translateX(5px);
      }
    }
  }
}

.slick-dots {
  list-style: none;
  margin: 0;
  padding: 15px 0;
  text-align: center;
  
  li {
    display: inline-block;
    vertical-align: top;
  }

  button {
    background-color: $c-white;
    text-indent: -9999px;
    width: 11px;
    height: 11px;
    border: 2px solid $c-blue;
    display: block;
    border-radius: 50%;
    margin: 0 6px;
    padding: 0;
    outline: none;
    transition: transform .2s ease-out;
  }

  .slick-active button {
    background-color: $c-blue;
    transform: scale(1.25);
  }
}

.input-text {
  background-color: $tr;
  color: $p;
  font-size: 21px;
  font-weight: 300;
  height: 52px;
  border-bottom: 1px solid $c-gray;
  outline: 0;
  transition: .2s ease-in-out;

  &::placeholder {
    color: $p;
    opacity: .5;
  }

  &:focus {
    border-color: $c-black;
  }
}

.textarea {
  background-color: $tr;
  color: $p;
  font-size: 21px;
  font-weight: 300;
  height: 122px;
  border: 1px solid $c-gray;
  outline: 0;
  transition: .2s ease-in-out;
  padding: 20px;
  resize: none;

  &::placeholder {
    color: $p;
    opacity: .5;
  }

  &:focus {
    border-color: $c-black;
  }
}

.input-text.error,
.textarea.error {
  border-color: $c-red;
}

form {
  label {
    font-size: 21px;
    font-weight: 300;
    color: rgba($p, .5);
    display: block;
    padding: 15px 0 10px;
  }
}

.contact-form {
  max-width: 720px;
  padding-top: 50px;
  opacity: 0;

  @include mq(xxs, md) {
    margin: 0 auto 20px;
  } 

  @include mq(sm) {
    margin: 0 auto 40px;
  } 

  h3 {
    font-size: 21px;
    line-height: 1.7;
    color: $t;
    font-weight: 300;

    @include mq(xxs, md) {
      margin: 0 0 30px;
    } 

    @include mq(sm) {
      margin: 0 0 40px;
    } 
  }
}

.form-gruop {
  position: relative;
  margin-bottom: 10px;

  &--message {
    margin-bottom: 30px;
  }
}

label.error {
  color: $c-red;
  font-size: 14px;
  padding: 5px 0 10px;
  text-transform: none;
}