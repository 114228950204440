ul.errors {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #f00;
}
.textarea + ul.errors {
  margin-top: -30px;
  margin-bottom: 30px;
}
p.success {
  color: #de791a;
}
.blog__widget ol li.active a {
  background-color: #00a3e0;
  color: #fff;  
}
.content--no-image {
  margin-top: 60px;
}
.books__list .stripe-button-el {
  background: none !important;
  box-shadow: none !important;
  border-radius: 30px !important;
  border: 1px solid #00a3e0 !important;
  padding: 0;
  margin-top: 10px;
  span {
    background-image: none !important;
    text-shadow: none;
    height: auto;
    font-weight: 400;
    background-color: #fff !important;
    border-color: #00a3e0 !important;
    color: #00a3e0 !important;
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    line-height: 1.2 !important;
    border: 0 !important;
    padding: 8px 30px 9px !important;
    border-radius: 30px !important;
    -webkit-transition: background .2s ease-out,color .2s ease-out !important;
    transition: background .2s ease-out,color .2s ease-out !important;
    font-size: 18px !important;
    font-family: Roboto !important;
    &:hover {
      background-color: #00a3e0 !important;
      color: #fff !important;
    }
  }
  &:hover {
    background-color: #00a3e0 !important;
    color: #fff !important;
  }
}
.blog.videos {
  justify-content: center;
  .blog__posts {
    max-width: 720px;
    margin-right: 0;
  }
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
