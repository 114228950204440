.top-message {
  background-color: $c-orange;
  font-size: 16px;
  line-height: 1.2;
  color: $c-white;
  font-weight: 300;
  text-align: center;
  display: block;
  padding: 10px 15px;

  strong {
    font-weight: 500;
  }
}

.site-header {
  background-color: $c-white;
  border-bottom: 1px solid $c-gray;
  overflow: hidden;
  position: relative;
  
  .wrap {
    display: flex;
    align-items: center;
    transition: transform .2s ease-out;

    @include mq(xxs, md) {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    @include mq(md) {
      height: 130px;
    }

    &.search-visible {
      transform: translateX(-100%);
    }
  }

  &__logo {
    display: inline-flex;
    align-items: center;
    
    @include mq(xxs, md) {
      max-width: 160px;
    }

    @include mq(md) {
      max-width: 236px;
    }
  }

  &__box {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  &__menu {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
    }

    li {
      @include mq(md, lg) {
        margin-left: 20px;
      }

      @include mq(lg) {
        margin-left: 40px;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    a {
      @include mq(md) {
        font-size: 16px;
        line-height: 1.2;
        color: $c-blue;
        padding: 15px 0;
        transition: border .2s ease-out, color .2s ease-out;
        border-bottom: 2px solid $tr;
      }

      &:hover {
        color: $c-orange;
        border-color: $c-orange;
      }
    }

    .active a {
      color: $c-orange;
      border-color: $c-orange;
    }

    @include mq(xxs, md) {
      display: none;
    }
  }

  &__search {
    width: 24px;
    height: 24px;
    display: flex;

    @include mq(xxs, md) {
      display: none;
    }

    @include mq(md, lg) {
      margin-left: 30px;
    }

    @include mq(lg) {
      margin-left: 50px;
    }

    svg {
      fill: $c-blue;
      transition: .2s ease-out;
    }

    &:hover svg {
      fill: $c-orange;
    }
  }

  &__mobile-menu {
    width: 24px;
    height: 19px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-left: 15px;
    overflow: hidden;

    span {
      height: 3px;
      width: 100%;
      background-color: $c-blue;
      transition: .2s ease-out;
    }

    @include mq(sm, md) {
      .mm-visible & {
        span {
          transform: rotate(45deg);
          background-color: $c-orange;
        }

        span:last-child {
          transform: translate3d(0 , 100%, 0);
          opacity: 0;
        }

        span:first-child {
          transform: translate3d(0 , 8px, 0) rotate(-45deg);
        }
      }
    }

    @include mq(md) {
      display: none;
    }
  }
}

.search-bar {
  background-color: $c-white;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform .2s ease-out;

  &.visible {
    transform: translateX(0);
  }

  .wrap {
    display: flex;
    justify-content: space-between;
  }

  .icon-search {
    width: 24px;
    height: 24px;
    fill: $c-blue;
    flex: 0 0 24px;

    @include mq(xxs, sm) {
      display: none;
    }
  }

  .search-bar__input {
    font-weight: 300;
    height: 40px;
    border: 0;
    outline: none;

    @include mq(xxs, sm) {
      margin: 0 10px 0 0;
      font-size: 14px;
    }

    @include mq(sm) {
      margin: 0 20px;
      font-size: 21px;
    }
  }

  .btn {
    @include mq(sm) {
      min-width: 200px;
    }
  }

  &__close {
    width: 20px;
    height: 20px;
    display: flex;
    position: relative;
    flex: 0 0 20px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      margin: -1px 0 0;
      background-color: $c-blue;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }

    @include mq(xxs, md) {
      margin-left: 10px;
    }

    @include mq(md) {
      margin-left: 45px;
    }
  }
}