.legacy {
  opacity: 0;

  &.active {
    opacity: 1;
  }
  
  @include mq(xxs, md) {
    text-align: center;
  }

  @include mq(xxs, sm) {
    padding: 0 0 50px;
  }

  @include mq(sm, md) {
    padding: 70px 0;
  }

  @include mq(md) {
    padding: 95px 0;
  }

  img {
    display: block;

    @include mq(xxs, md) {
      margin: 0 auto 30px;
    }

    @include mq(md) {
      padding-right: 20px;
    }
  }

  &__image {
    @include mq(xxs, sm) {
      margin: 0 -15px;
    }
  }

  h2 {
    font-weight: 500;
    color: $p;

    @include mq(xxs, sm) {
      font-size: 24px;
      margin: 0 0 20px;
    }

    @include mq(sm, md) {
      font-size: 26px;
      margin: 0 0 20px;
    }

    @include mq(md) {
      font-size: 28px;
    }

    @include mq(md, lg) {
      margin: 0 0 20px;
    }

    @include mq(lg) {
      margin: 0 0 35px;
    }
  }

  p {
    font-weight: 300;
    line-height: 1.7;
    margin: 0;

    @include mq(xxs, sm) {
      font-size: 14px;
    }

    @include mq(sm, md) {
      font-size: 16px;
    }

    @include mq(md) {
      font-size: 18px;
    }
  }
}