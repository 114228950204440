.brands {
  opacity: 0;

  &.active {
    opacity: 1;
  }
  
  &__list {
    margin-right: -1px;
  }

  &__item {
    position: relative;
    height: 253px;
    background-color: $c-white;
    overflow: hidden;
    border: 1px solid $c-gray;
    border-left: 0;

    @include mq(md) {
      height: 253px;
    }

    &:hover {
      .brands__mask {
        opacity: 1;
      }

      .brands__hidden {
        opacity: 1;
      }
    }
  }

  &__visible {
    height: 100%;
    position: relative;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 110%;
    }
  }

  &__hidden {
    color: $c-white;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity .3s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  h3 {
    line-height: 1.2;
    font-weight: 700;
    margin: 0 0 15px;

    @include mq(xxs, sm) {
      font-size: 24px;
    }

    @include mq(sm, md) {
      font-size: 26px;
    }

    @include mq(md) {
      font-size: 28px;
    }
  }

  P {
    @include mq(xxs, sm) {
      font-size: 14px;
      margin: 0 0 25px;
    }

    @include mq(sm, md) {
      font-size: 15px;
      margin: 0 0 30px;
    }

    @include mq(md) {
      font-size: 16px;
      margin: 0 0 35px;
    }
  }

  &__mask {
    background-color: $c-orange;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity .2s ease-out;
  }
}

.brands-item {
  padding: 50px 0 0;

  &__box {
    border-top: 1px solid $c-gray;
    display: flex;
    flex-wrap: wrap;
    opacity: 0;

    @include mq(xxs, sm) {
      padding: 20px 0;
    }

    @include mq(sm) {
      padding: 40px 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__image {
    @include mq(xxs, sm) {
      width: 100%;
      margin-bottom: 20px;
    }

    @include mq(sm) {
      flex: 0 0 310px;
    }
  }

  &__text {
    flex: 1;
  }

  &__top {
    @include mq(xxs, sm) {
      margin: 0 0 30px;
    }

    @include mq(sm) {
      margin: 0 0 20px;
    }

    @include mq(sm) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
    }

    .icon {
      transform: rotate(-45deg);
    }
  }

  &__title {
    @include mq(sm, md) {
      width: 100%;
    }
  }

  h2 {
    font-weight: 500;
    line-height: 1.2;
    color: $s;
    margin: 0 0 10px;

    @include mq(xxs, sm) {
      font-size: 20px;
    }

    @include mq(sm, md) {
      font-size: 22px;
    }

    @include mq(md) {
      font-size: 24px;
    }
  } 

  h3 {
    font-weight: 300;
    line-height: 1.2;
    color: $c-orange;
    margin: 0 0 15px;

    @include mq(xxs, sm) {
      font-size: 19px;
    }

    @include mq(sm, md) {
      font-size: 20px;
    }

    @include mq(md) {
      font-size: 21px;
    }
  }

  p {
    font-weight: 300;
    line-height: 1.7;
    margin: 0 0 25px;

    &:last-child {
      margin-bottom: 0;
    }

    @include mq(xxs, sm) {
      font-size: 16px;
    }

    @include mq(sm, md) {
      font-size: 17px;
    }

    @include mq(md) {
      font-size: 18px;
    }

  }
}