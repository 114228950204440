@import "../../../node_modules/normalize.css/normalize.css";

* {
  box-sizing: border-box;
}

*::before, 
*::after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  width: auto;
  height: auto;
}

.map-canvas img {
  max-width: none;
}