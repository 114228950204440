.events {
  padding-top: 50px;

  &__item {
    border-top: 1px solid $c-gray;
    display: flex;
    flex-wrap: wrap;
    opacity: 0;

    @include mq(xxs, sm) {
      padding: 20px 0;
    }

    @include mq(sm) {
      padding: 40px 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__date {
    background-color: rgba($c-orange, .1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 138px;

    @include mq(xxs, sm) {
      width: 100%;
      margin-bottom: 20px;
    }

    @include mq(sm) {
      flex: 0 0 138px;
    }

    span {
      font-size: 16px;
      font-weight: 300;
      text-transform: uppercase;
    }

    h4 {
      font-size: 36px;
      line-height: 1;
      color: $s;
      font-weight: 500;
      margin: 5px 0;
      display: flex;
      align-items: center;
    }

    sup {
      font-size: 18px;
      margin-left: 2px;
    }
  }

  &__text {
    flex: 1;

    @include mq(xxs, sm) {
      text-align: center;
    }

    @include mq(sm, md) {
      margin: 0 0 0 20px;
    }

    @include mq(md) {
      margin: 0 0 0 40px;
    }
  }

  &__top {
    padding: 15px 0 0;

    @include mq(xxs, sm) {
      margin: 0 0 30px;
    }

    @include mq(sm) {
      margin: 0 0 20px;
    }

    @include mq(sm) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
    }

    .icon {
      transform: rotate(-45deg);
    }
  }

  &__title {
    @include mq(sm, md) {
      width: 100%;
    }
  }

  h2 {
    font-weight: 500;
    line-height: 1.2;
    color: $s;
    margin: 0 0 10px;

    @include mq(xxs, sm) {
      font-size: 20px;
    }

    @include mq(sm, md) {
      font-size: 22px;
    }

    @include mq(md) {
      font-size: 24px;
    }
  } 

  h3 {
    font-weight: 300;
    line-height: 1.2;
    color: $c-orange;
    margin: 0 0 15px;

    @include mq(xxs, sm) {
      font-size: 19px;
    }

    @include mq(sm, md) {
      font-size: 20px;
    }

    @include mq(md) {
      font-size: 21px;
    }
  }

  p {
    font-weight: 300;
    line-height: 1.7;
    margin: 0 0 25px;

    &:last-child {
      margin-bottom: 0;
    }

    @include mq(xxs, sm) {
      font-size: 16px;
    }

    @include mq(sm, md) {
      font-size: 17px;
    }

    @include mq(md) {
      font-size: 18px;
    }

  }
}