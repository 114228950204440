@mixin mq($bpmin, $bpmax: false) {
  @each $sz, $mq in $sizes {
    @if $sz == $bpmin {
      $a: $mq;
      @if $bpmax {
        @each $sz, $mq in $sizes {
          @if $sz == $bpmax {
            @media (min-width: #{$a}px) and (max-width: $mq - 1px) { @content }
          }
        }
      } @else {
        @media (min-width: #{$a}px) { @content }
      }
    }
  }
}

$sizes: (
  xxs: 0,
  xs: 480,
  sm: 768,
  md: 992,
  lg: 1280
);

// Centered element

@mixin el-center {
  position: absolute;
  left: 50%; right: 50%;
  transform: transform(-50%, -50%);
}

