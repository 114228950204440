.about {
  border: 8px solid rgba($c-white, .5);
  position: relative;
  z-index: 5;
  opacity: 0;

  &.active {
    opacity: 1;
  }
  
  @include mq(xxs, sm) {
    margin-top: -50px;
  }

  @include mq(sm, md) {
    margin-top: -80px;
  }

  @include mq(md) {
    margin-top: -110px;
  }

  &__bg {
    background-color: $c-white;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    @include mq(xxs, sm) {
      padding: 15px;
    }

    @include mq(sm, md) {
      padding: 40px 20px 59px;
    }

    @include mq(md, lg) {
      padding: 74px 40px 59px;
    }

    @include mq(lg) {
      padding: 74px 74px 59px;
    }
  }

  &__image {
    position: relative;

    @include mq(xxs, sm) {
      width: 100%;
    }

    @include mq(sm, md) {
      flex: 0 0 280px;
      margin-right: 20px;
    }

    @include mq(md) {
      flex: 0 0 350px;
    }

    @include mq(md, lg) {
      margin-right: 30px;
    }

    @include mq(lg) {
      margin-right: 60px;
    }

    img {
      display: block;
    }
  }
  
  &__image-avatar {
    border: 1px solid $c-gray;
  }

  &__image-amazon {
    position: absolute;

    @include mq(xxs, sm) {
      top: -40px;
      left: -10px;
    }

    @include mq(sm) {
      top: -40px;
      left: -30px;
    }
  }

  &__text {
    flex: 1;

    @include mq(xxs, sm) {
      text-align: center;
      padding-top: 20px;
    }
  }

  h2 {
    color: $c-primary;
    font-weight: 500;

    @include mq(xxs, sm) {
      font-size: 26px;
      margin: 0 0 10px;
    }

    @include mq(sm, md) {
      font-size: 27px;
      margin: 0 0 30px;
    }

    @include mq(md) {
      font-size: 28px;
      margin: 0 0 30px;
    }
  }

  strong {
    font-weight: 500;
    color: $c-blue;
  }

  p {
    line-height: 1.6666666667;
    font-weight: 300;

    @include mq(xxs, sm) {
      font-size: 16px;
      margin: 0 0 20px;
    }

    @include mq(sm, md) {
      font-size: 17px;
      margin: 0 0 25px;
    }

    @include mq(md) {
      font-size: 18px;
      margin: 0 0 30px;
    }
  }

  &__links {
    a {
      @include mq(xxs, sm) {
        margin-bottom: 15px;
      }

      @include mq(sm) {
        margin-right: 20px;
        margin-bottom: 15px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}