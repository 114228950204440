.reviews {
  position: relative;
  opacity: 0;

  &.active {
    opacity: 1;
  }
  
  @include mq(xxs, sm) {
    margin: 0 0 30px;
    padding: 30px 0 0;
  }

  @include mq(sm) {
    margin: 0 0 60px;
    padding: 30px 0 0;
  }

  .slick-arrow {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: 1px solid $c-blue;
    background-color: $c-white;
    outline: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color .2s ease-out;

    svg {
      transition: fill .2s ease-out;
      fill: $c-blue;
      width: 18px;
      height: 14px;
      margin-bottom: -1px;
    }

    &:hover {
      background-color: $c-blue;

      svg {
        fill: $c-white;
      }
    }
  }

  &__prev {
    left: 0;

    svg {
      transform: scaleX(-1);
      filter: FlipH;
    }
  }

  &__next {
    right: 0;
  }

  &__slider {
    @include mq(sm) {
      margin: 0 80px;
    }
  }

  &__item {
    text-align: center;

    h3 {
      font-weight: 400;
      line-height: 1.5;
      color: $p;
      margin: 0 0 15px;

      @include mq(xs, sm) {
        font-size: 20px;
      }

      @include mq(sm, md) {
        font-size: 22px;
      }

      @include mq(md) {
        font-size: 24px;
      }
    }

    p {
      line-height: 1.2;
      font-weight: 300;
      color: $c-orange;
      margin: 0;

      @include mq(xs, sm) {
        font-size: 17px;
      }

      @include mq(sm, md) {
        font-size: 19px;
      }

      @include mq(md) {
        font-size: 21px;
      }
    }
  }

  &__arrows {
    @include mq(xxs, sm) {
      display: none;
    }
  }

  .slick-dots {
    padding-top: 25px;
  }

  &--item {
    padding-top: 0;
  }
}