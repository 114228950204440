.books {
  opacity: 0;

  &.active {
    opacity: 1;
  }
  
  &--item {
    // @include mq(xxs, sm) {
    //   margin-bottom: 50px;
    // }

    // @include mq(sm, md) {
    //   margin-bottom: 60px;
    // }

    // @include mq(md) {
    //   margin-bottom: 80px;
    // }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__bg {
    background-color: $c-white;
    border-top: 1px solid $c-gray;
    border-bottom: 1px solid $c-gray;

    .books--item & {
      border-bottom: 0;
    }
  }

  &__item {
    position: relative;

    @include mq(xxs, sm) {
      text-align: center;
      margin: 0 -15px;
      padding: 30px 15px;
      border-bottom: 1px solid $c-gray;

      &:last-child {
        border-bottom: 0;
      }
    }

    @include mq(sm) {
      width: 50%;
      padding-bottom: 134px;
    }

    @include mq(sm) {
      padding-top: 50px;

      &:last-child {
        border-left: 1px solid $c-gray;
      }
    }

    @include mq(sm, md) {
      &:first-child {
        padding-right: 30px;
        padding-left: 15px;
      }

      &:last-child {
        padding-left: 30px;
        padding-right: 15px;
      }
    }

    @include mq(md, lg) {
      &:first-child {
        padding-right: 50px;
      }

      &:last-child {
        padding-left: 50px;
      }
    }

    @include mq(lg) {
      &:first-child {
        padding-right: 70px;
      }

      &:last-child {
        padding-left: 50px;
      }
    }
  }

  &__top {
    display: flex;
    align-items: center; 
    margin: 0 0 30px;

    @include mq(xxs, sm) {
      text-align: left;
    }

    h2 {
      font-weight: 500;
      line-height: 1.2;
      color: $c-primary;
      margin: 0 0 24px;

      @include mq(xxs, sm) {
        font-size: 20px;
      }

      @include mq(sm, md) {
        font-size: 22px;
      }

      @include mq(md) {
        font-size: 24px;
      }
    }

    h3 {
      font-weight: 300;
      line-height: 1.2;
      color: $c-orange;
      margin: 0;

      @include mq(xxs, sm) {
        font-size: 17px;
      }

      @include mq(sm, md) {
        font-size: 19px;
      }

      @include mq(md) {
        font-size: 21px;
      }

      a {
        color: $c-orange;
      }
    }
  }

  &__image {
    max-height: 190px; 
    width: 160px;  

    img {
      max-height: 150px;
    }
  }

  &__desc {
    @include mq(xxs, sm) {
      text-align: center;
    }

    p {
      font-weight: 300;
      line-height: 1.7;
      margin: 0 0 10px;

      @include mq(xxs, sm) {
        font-size: 14px;
      }

      @include mq(sm, md) {
        font-size: 16px;
      }

      @include mq(md) {
        font-size: 18px;
      }
    }
  }

  .btn {
    @include mq(sm) {
      position: absolute;
      left: 50%;
      bottom: 60px;
      transform: translateX(-50%);
    }
  }
}