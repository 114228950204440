.gallery {
  display: flex;
  flex-wrap: wrap;
  opacity: 0;

  &.active {
    opacity: 1;
  }
  
  &__item {
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    height: 255px;
    overflow: hidden;

    @include mq(xxs, sm) {
      width: 100%;
    }

    @include mq(sm, md) {
      width: calc(100% / 2);
    }

    @include mq(md) {
      width: calc(100% / 4);
    }

    h3 {
      background-color: rgba($c-orange, .9);
      font-size: 16px;
      font-weight: 400;
      color: $c-white;
      position: absolute;
      text-align: center;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px;
      margin: 0;
      transition: transform .2s ease-in-out;

      @include mq(md) {
        transform: translate3d(0, 100%, 0);
      }
    }

    &:hover h3 {
      transform: translate3d(0, 0, 0);
    }
  }
}