.mobile-menu {
  background: $c-white;
  position: fixed;
  z-index: 99;
  right: 0;
  padding: 20px;
  width: 100%;
  overflow-y: auto;
  transform: translate3d(110%, 0, 0);
  transition: transform .4s ease-in-out;

  .mm-visible & {
    transform: translate3d(0, 0, 0);
  }

  @include mq(xxs, sm) {
    top: 0;
    bottom: 0;
  }

  @include mq(sm) {
    top: 71px;
    bottom: 0;
    max-width: 420px;
    box-shadow: 0 30px 30px rgba($c-black, .2);

    .has-top-message & {
      top: 105px;
    }
  }

  &__close {
    width: 26px;
    height: 26px;
    display: flex;
    position: absolute;
    top: 15px;
    right: 15px;

    @include mq (sm) {
      display: none;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      margin-top: -1px;
      background-color: $c-orange;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &__nav {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    a {
      font-size: 16px;
      color: #00a3e0;
      display: block;
      padding: 8px 0;
    }

    .active a {
      color: $c-orange;
    }
  }
}