.site-footer {
  border-top: 1px solid $c-gray;
  opacity: 0;

  &.active {
    opacity: 1;
  }
  

  @include mq(xxs, sm) {
    padding: 10px 0 50px;
  }

  @include mq(sm) {
    padding: 10px 0 130px;
  }

  &__social {
    display: flex;
    justify-content: center;
    margin: 0 0 50px;

    a {
      display: flex;

      @include mq(xxs, sm) {
        margin: 0 15px;
      }

      @include mq(sm) {
        margin: 0 22px;
      }

      &:hover svg {
        transform: scale(1.2);
      }
    }

    svg {
      transition: transform .2s ease-out;
    }
  }

  .icon-facebook {
    width: 34px;
    height: 34px;
    fill: #3b5998;
  }

  .icon-twitter {
    width: 41px;
    height: 34px;
    fill: #00aced;
  }

  .icon-youtube {
    width: 34px;
    height: 34px;
    fill: #ff3434;
  }

  &__text {
    text-align: center;

    a,
    p {
      line-height: 1.2;
      color: $c-footer;
      display: inline-block;
      vertical-align: top;
      margin: 0 10px 10px;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}