.feature-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0;

  @include mq(xxs, sm) {
    height: 420px;
  }

  @include mq(sm, md) {
    height: 580px;
  }
  
  @include mq(md) {
    height: 800px;
  }

  .wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    @include mq(xxs, sm) {
      height: 420px;
    }

    @include mq(sm, md) {
      height: 580px;
    }

    @include mq(md) {
      height: 800px;
    }
  }

  h1 {
    line-height: 1.2;
    font-weight: bold;
    color: $c-white;

    @include mq(xxs, sm) {
      font-size: 34px;
    }

    @include mq(sm, md) {
      font-size: 44px;
    }

    @include mq(md) {
      font-size: 54px;
    }
  }

  span {
    display: inline-block;
  }
}